$border-radius: 0.2rem;

.chat-wrapper {
  color: #333;
  width: 100%;
  margin: 0 auto;
  .message-item {
    .assistant {
      background: rgb(248, 248, 255);
    }
    .user {
      background: rgb(248, 255, 248);
    }
    .system {
      background: rgb(248, 248, 248);
    }
    margin: 0.5rem 0.5rem;
    border-radius: $border-radius;
    &:hover {
      background-color: rgb(0 0 0 / 5%);
    }
    .meta {
      display: flex;
      grid-gap: 0.75rem;
      gap: 0.75rem;
    }
    .avatar {
      span {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-top: 0.5em;
        border-radius: 50%;
      }
      .assistant {
        background: rgb(73, 169, 253);
      }
      .user {
        background: rgb(61 168 101);
      }
      .system {
        background: rgb(93 93 93);
      }
    }
    .message {
      flex: 1;
      overflow: hidden;
      font-size: 14px;
      code:not(.hljs) {
        font-weight: 600;
        font-size: 0.875em;
        font-family: system-ui, sans-serif;
      }
      p {
        margin: 0.5em 0;
        line-height: 1.75;
      }
      pre {
        padding: 0.4rem 1rem;
        font-size: 0.8rem;
        background-color: #282c34;
        border-radius: $border-radius;
      }
      [relative] {
        position: relative;
      }
      .copy-btn {
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        color: rgb(148 163 184);
        background-color: rgb(0 0 0 / 20%);
        border-radius: $border-radius;
        cursor: pointer;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        transition-property: all;
        div {
          position: absolute;
          top: -24px;
          display: none;
          padding: 0.2rem 0.4rem;
          color: white;
          font-size: 12px;
          background-color: #000;
          border-radius: $border-radius;
        }
        svg {
          display: block;
        }
        &:hover {
          > div {
            display: block;
          }
        }
      }
    }
  }
  .thinking {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    justify-content: center;
    margin-top: 0.5rem;
    padding: 0.75rem;
    font-size: 16px;
    background-color: #e5e7eb;
    border-radius: $border-radius;
    .stop {
      padding: 0 0.75rem;
      font-size: 14px;
      border: 1px solid #282c34;
      border-radius: $border-radius;
      cursor: pointer;
    }
  }
  .send-bar {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-top: 0.5rem;
    .input {
      flex: 1;
      padding: 0.75rem;
      font-size: 16px;
      border: none;
      border-radius: $border-radius;
      resize: none;
      &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }
    }
    .button {
      min-width: 3rem;
      height: 3rem;
      padding: 0 0.75rem;
      font-size: 16px;
      background-color: #e5e7eb;
      border: 0 solid #e5e7eb;
      border-radius: $border-radius;
      cursor: pointer;
      &:active {
        background-color: #ddd;
      }
    }
  }
}
